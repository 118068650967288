/* Reset margins and paddings to ensure consistent styling across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set a white background color for the entire page */
body {
  background-color: #fff;
  font-family: Arial, sans-serif;
  color: #333; /* Optional: Set a default text color */
}

/* Ensure the main container (if any) takes full height */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
