.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8); /* Transparent background */
  backdrop-filter: blur(10px); /* Optional: adds a blur effect */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

.navbar-logo .logo {
  height: 70px; /* Adjust logo size */
  position: absolute;
  transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
}

.navbar-logo .fade-in {
  opacity: 1; /* Show the image */
}

.navbar-logo .fade-out {
  opacity: 0; /* Hide the image */
}

.navbar.scrolled .logo {
  position: absolute;
}

.navbar.scrolled .fade-in {
  opacity: 1; /* Image to show when scrolled */
}

.navbar.scrolled .fade-out {
  opacity: 0; /* Image to hide when scrolled */
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  align-items: center;
}

.navbar-links li {
  margin: 0;
}

.navbar-links a {
  color: #b92d6a; /* Purple color for text */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #b92d6a; /* Optional: highlight color on hover */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .navbar {
    justify-content: center; /* Center align navbar items */
    padding: 10px; /* Adjust padding if needed */
  }

  .navbar-links {
    display: flex;
    flex-direction: row; /* horizontal links on mobile */
    gap: 1rem; /* Space between links */
    margin: 0; /* Remove default margin */
    align-items: center; /* Center align links */
  }
}
