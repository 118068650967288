.contact-container {
    max-width: 600px; /* Adjust the max width as needed */
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h2 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 4rem;
    color: #b92d6a ;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
  }
  
  .contact-form label {
    width: 100%;
    max-width: 400px;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    max-width: 400px; /* Limit the width of inputs */
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    height: 150px;
    resize: vertical;
  }
  
  .contact-form button {
    width: 100%;
    max-width: 200px; /* Limit the width of the button */
    padding: 0.8rem 1.2rem;
    background-color: #b92d6a; /* Adjust color as needed */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #4a1f9e; /* Adjust color as needed */
  }
  