.header {
  position: relative;
  background: url('./mountain-background.jpeg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 0 20px;
  overflow: hidden;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.header h1,
.header p,
.cta-button {
  position: relative;
  z-index: 2;
}

.header h1 {
  font-size: 3rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.header p {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #d53f7b;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background-color: #b92d6a;
}

.header-image {
  display: none; /* Hide the image on larger screens */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #b92d6a;
  margin-top: 20px;
  transition: filter 0.3s ease; /* Smooth transition for filter changes */
}

@media (max-width: 768px) {
  .header {
    background-attachment: scroll; /* Prevents fixed background zoom issues */
    background-size: cover; /* Ensure the image covers the entire area */
  }

  .header-image {
    display: block; /* Show the image on small screens */
    margin-top: 10%;
    z-index: 2;
  }

  .cta-button {
    margin-bottom: 10%;
  }
}

/* Cloud styles */
.cloud {
  position: absolute;
  top: 30%; /* Adjust vertical position for better alignment */
  width: 400px; /* Larger size for desktop screens */
  height: auto;
  z-index: 3; /* Above the header content */
  transition: transform 2s ease-out; /* Smooth transition */
}

.cloud-left {
  left: 15%; /* Adjust starting position */
  transform: translateX(-50%); /* Initial position */
}

.cloud-right {
  right: 14%; /* Adjust starting position */
  transform: translateX(50%); /* Initial position */
}

.animate-clouds .cloud-left {
  transform: translateX(-200%); /* Move off screen to the left */
}

.animate-clouds .cloud-right {
  transform: translateX(200%); /* Move off screen to the right */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .cloud {
    width: 350px; /* Smaller size for mobile screens */
    top: 1%; /* Adjust the vertical position for mobile screens */
  }

  .cloud-left {
    left: 6%; /* Adjust starting position for mobile screens */
    transform: translateX(-30%); /* Adjust initial position for mobile screens */
  }

  .cloud-right {
    right: 10%; /* Adjust starting position for mobile screens */
    transform: translateX(30%); /* Adjust initial position for mobile screens */
  }

  .animate-clouds .cloud-left {
    transform: translateX(-150%); /* Move completely off screen to the left on mobile */
  }

  .animate-clouds .cloud-right {
    transform: translateX(150%); /* Move completely off screen to the right on mobile */
  }
}