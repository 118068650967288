@keyframes bounceInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    60% {
      transform: translateX(30%);
      opacity: 1;
    }
    80% {
      transform: translateX(-10%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .myName {
    color: #b92d6a;
    display: inline-block; /* Needed for transform */
    opacity: 0; /* Start hidden */
    transform: translateX(-100%); /* Start off-screen */
  }
  
  .myName.bounce {
    animation: bounceInLeft 1.5s ease-in-out;
    opacity: 1; /* Visible when animating */
    transform: translateX(0); /* Ensure final position is correct */
  }
  
  .services {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 2rem;
    background-color: #d8d0d0;
    text-align: left;
  }
  
  .services .text-container {
    max-width: 700px;
    margin-left: 9%;
  }
  
  .services h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #0b0318;
  }
  
  .services p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .services .image-container {
    max-width: 400px;
    margin-right: 9%;
  }
  
  .services .image-container img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: 3px solid #b92d6a;
  }
  
  @media (max-width: 768px) {
    .services {
      flex-direction: column;
      padding: 2rem 1rem;
    }
  
    .services .text-container {
      margin-left: 0;
      max-width: 90%;
    }
  
    .services .image-container {
      display: none;
    }
  }
  