.whatsapp-button {
  position: fixed;
  bottom: 1rem; /* Adjust as needed */
  right: 1rem; /* Adjust as needed */
  z-index: 1000; /* Make sure it's above other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button img {
  width: 200px; /* Default size for larger screens */
  height: auto; /* Maintain aspect ratio */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .whatsapp-button img {
    width: 140px; /* Half the size on mobile screens */
  }
}
